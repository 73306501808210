<template>
    <div class="best-slider-outer-wrap">
        <div class="best-slider-inner-back">
            <div class="best-slider-wrap">
                <div class="best-slider-item-with-arrow">
                    <div class="best-slider-item-list" ref="sliderList">
                        <!-- 너가 반복 이긴 한데.. -->
                        <div class="best-slider-item" v-for="(wineInfo) in wineList" v-bind:key="wineInfo.key">
                            <div class="best-slider-pc-image" :style="{backgroundImage:`url(${wineInfo.wine_image})`}"></div>
                            <div class="best-slider-content">
                                <p class="best-slider-best-wine">Best Fine Wine NFT</p>
                                <h4 class="best-slider-title">{{wineInfo.wine_name}}</h4>
                                <div class="best-slider-mobile-image" :style="{backgroundImage:`url(${wineInfo.wine_image})`}"></div>
                                <div class="best-slider-character-info-wrap">

                                    <div class="best-slider-character-item">
                                        <span class="title">Type</span>
                                        <span class="content">{{wineInfo.properties.type}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Grape</span>
                                        <span class="content">{{wineInfo.properties.grape}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Format</span>
                                        <span class="content">{{wineInfo.properties.format}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Case * Bottle</span>
                                        <span class="content">{{wineInfo.properties['case * bottle']}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Country</span>
                                        <span class="content">{{wineInfo.properties.country}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Region</span>
                                        <span class="content">{{wineInfo.properties.region}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Subregion</span>
                                        <span class="content">{{wineInfo.properties.subregion_1}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Subregion 2</span>
                                        <span class="content">{{wineInfo.properties.subregion_2}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Subregion 3</span>
                                        <span class="content">{{wineInfo.properties.subregion_3}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Grade</span>
                                        <span class="content">{{wineInfo.properties.grade}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Vineyard</span>
                                        <span class="content">{{wineInfo.properties.vineyard}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                      <span class="title">Vintage</span>
                                      <span class="content">{{wineInfo.properties.vintage}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">Minter</span>
                                        <span class="content">{{wineInfo.properties.minter}}</span>
                                    </div>

                                    <div class="best-slider-character-item">
                                        <span class="title">WMV</span>
                                        <span class="content">{{wineInfo.properties.wmv}}</span>
                                    </div>
                                </div>
                                <!--<div class="best-slider-character-info-wrap-t2">
&lt;!&ndash;                                  <div class="best-slider-character-item">&ndash;&gt;
&lt;!&ndash;                                    <span class="title">Serial Number</span>&ndash;&gt;
&lt;!&ndash;                                    <span class="content">{{wineInfo.properties.serial}}</span>&ndash;&gt;
&lt;!&ndash;                                  </div>&ndash;&gt;

                                    <div class="best-slider-character-item">
                                        <span class="title" style="width: 95px;">Minter</span>
                                        <span class="content">{{wineInfo.properties.minter}}</span>
                                    </div>
                                  <div class="best-slider-character-item">
                                    <span class="title" style="width: 220px;">WMV</span>
                                    <span class="content">{{wineInfo.properties.wmv}}</span>
                                  </div>

                                </div>-->
                                <div class="best-slider-nft-info-wrap">
                                    <table>
                                        <tr>
                                            <th>소유자</th>
                                            <th>최종 거래 가격</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="coin-price" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;" :title="wineInfo.owner">{{wineInfo.owner}}</span>
                                            </td>
                                            <td>
                                                    <span class="coin-price">
                                                      <img src="~@/assets/images/icon/usdc-icon.png"/>
                                                      {{$numberFormat(parseInt(wineInfo.last_price)/1000000)}}
                                                      <br/>
                                                      <span class="won-price">({{$numberFormat(contract.toWon(parseInt(wineInfo.last_price)/1000000)).split('.')[0]}}원)</span>
                                                    </span>
                                            </td>
                                        </tr>
                                    </table>
                                    <span>· 1분전 환율, 와넥스 이용내역 기준입니다.</span>
                                </div>
                                <div class="winex-btn primary-btn h40" @click="$router.push(`/market/detail/${wineInfo.market_key}/${wineInfo.nft_key}`)">더보기</div>
                            </div>
                        </div>

                    </div>
                    <div class="best-slider-prev-btn" @click="prevItem"></div>
                    <div class="best-slider-next-btn" @click="nextItem"></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import getContractApi from "../../api/contract"
    export default {
        name: "BestSlider",
        data() {
            return {
                wineList:[

                ],
                sliderWith:window.innerWidth,
                currentIndex:0,
                windowInnerWidth:window.innerWidth
            }
        },
        created() {
            this.contract = getContractApi();
        },
        mounted() {
            let self = this
            this.$store.dispatch('bestWineNFT/getBestWineList').then((data) => {
                self.wineList = data
            }).then(() => {
                let bestSliderItemList = self.$refs.sliderList.querySelectorAll('.best-slider-item')
                window.addEventListener('resize', function () {
                    self.sliderWith = window.innerWidth
                    for(let i = 0; i < bestSliderItemList.length; i++) {
                        if(self.sliderWith < 1200) {
                            if(i === self.currentIndex) {
                                bestSliderItemList[i].style.left = `0px`
                            } else {
                                bestSliderItemList[i].style.left = `calc(0px - ${self.sliderWith}px)`
                            }

                        } else {
                            if(i === self.currentIndex) {
                                bestSliderItemList[i].style.left = `calc(50%)`
                            } else {
                                bestSliderItemList[i].style.left = `calc(50% - ${self.sliderWith}px)`
                            }

                        }
                    }
                })
                /*if(this.$isMobile()) {
                    this.sliderWith = this.$refs.sliderList.clientWidth
                }*/


                // 싹다 안보이게 숙청시켜 ~~
                for(let i = 1; i < bestSliderItemList.length; i++) {
                    if(self.sliderWith < 1200) {
                        bestSliderItemList[i].style.left = `calc(0px - ${this.sliderWith}px)`
                    } else {
                        bestSliderItemList[i].style.left = `calc(50% - ${this.sliderWith}px)`
                    }
                }
            })


        },
        methods: {
            nextItem() {
                let bestSliderItemList = this.$refs.sliderList.querySelectorAll('.best-slider-item')
                let nextIndex = this.currentIndex + 1
                if(nextIndex > this.wineList.length - 1) {
                    nextIndex = 0
                }

                if(this.sliderWith < 1200) {
                    /* 애니메이션 세팅용 */
                    bestSliderItemList[nextIndex].style.left = `calc(0% - ${this.sliderWith}px)`

                    /* 애니메이션 처리  (추후)*/
                    this.$gsap.to(bestSliderItemList[this.currentIndex], 0.3, {left:`calc(0% + ${this.sliderWith}px)`})
                    this.$gsap.to(bestSliderItemList[nextIndex], 0.3, {left:`calc(0%)`})
                    for(let i = 0; i < bestSliderItemList[nextIndex].children[1].children.length; i++) {
                        let duration = 0.2 + (i * 0.15)
                        bestSliderItemList[nextIndex].children[1].children[i].style.position = "relative"
                        this.$gsap.fromTo(bestSliderItemList[nextIndex].children[1].children[i], {left:"-300px"}, {left:"0", duration:duration})
                    }
                } else {
                    /* 애니메이션 세팅용 */
                    bestSliderItemList[nextIndex].style.left = `calc(50% - ${this.sliderWith}px)`

                    /* 애니메이션 처리  (추후)*/
                    this.$gsap.to(bestSliderItemList[this.currentIndex], 0.3, {left:`calc(50% + ${this.sliderWith}px)`})
                    this.$gsap.to(bestSliderItemList[nextIndex], 0.3, {left:`calc(50%)`})
                    for(let i = 0; i < bestSliderItemList[nextIndex].children[1].children.length; i++) {
                        let duration = 0.2 + (i * 0.15)
                        bestSliderItemList[nextIndex].children[1].children[i].style.position = "relative"
                        if(bestSliderItemList[nextIndex].children[1].children[i].className !== 'best-slider-mobile-image') {
                            this.$gsap.fromTo(bestSliderItemList[nextIndex].children[1].children[i], {left:"-500px"}, {left:"0", duration:duration})
                        }

                    }
                }


                this.currentIndex = nextIndex
            },
            prevItem() {
                let bestSliderItemList = this.$refs.sliderList.querySelectorAll('.best-slider-item')
                let nextIndex = this.currentIndex - 1
                if(nextIndex < 0) {
                    nextIndex = this.wineList.length - 1
                }
                /* Animation Test */

                if(this.sliderWith < 1200) {
                    /* 애니메이션 세팅용 */
                    bestSliderItemList[nextIndex].style.left = `calc(0% + ${this.sliderWith}px)`

                    /* 애니메이션 처리  (추후)*/
                    this.$gsap.to(bestSliderItemList[this.currentIndex], 0.3, {left:`calc(0% - ${this.sliderWith}px)`})
                    this.$gsap.to(bestSliderItemList[nextIndex], 0.3, {left:`calc(0%)`})
                    for(let i = 0; i < bestSliderItemList[nextIndex].children[1].children.length; i++) {
                        let duration = 0.2 + (i * 0.15)
                        bestSliderItemList[nextIndex].children[1].children[i].style.position = "relative"
                        this.$gsap.fromTo(bestSliderItemList[nextIndex].children[1].children[i], {left:"300px"}, {left:"0", duration:duration})
                    }

                } else {
                    /* 애니메이션 세팅용 */
                    bestSliderItemList[nextIndex].style.left = `calc(50% + ${this.sliderWith}px)`

                    /* 애니메이션 처리  (추후)*/
                    this.$gsap.to(bestSliderItemList[this.currentIndex], 0.3, {left:`calc(50% - ${this.sliderWith}px)`})
                    this.$gsap.to(bestSliderItemList[nextIndex], 0.3, {left:`calc(50%)`})
                    for(let i = 0; i < bestSliderItemList[nextIndex].children[1].children.length; i++) {
                        let duration = 0.2 + (i * 0.15)
                        bestSliderItemList[nextIndex].children[1].children[i].style.position = "relative"
                        if(bestSliderItemList[nextIndex].children[1].children[i].className !== 'best-slider-mobile-image') {
                            this.$gsap.fromTo(bestSliderItemList[nextIndex].children[1].children[i], {left:"500px"}, {left:"0", duration:duration})
                        }

                    }
                }


                this.currentIndex = nextIndex

            }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/best-slider.scss";
</style>

